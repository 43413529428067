/* src/App.css */
body::after {
  content: "";
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;   /* Place it behind the content */
  margin: 0;
  padding: 0;
  background: url('../src/assets/svg/background.svg') no-repeat center center;
  background-size: cover;
}

.white-background {
  padding-bottom: 100px;
  background-color: white;
  min-height: 100vh;
  overflow: auto;
}


html {
  scroll-behavior: smooth;
}

.App {
  color: #778383;
}

.MuiButton-root:hover {
  background: #83D3CA;
}

.MuiButton-root.MuiButton-colorPrimary {
  color: #83D3CA;
}
.logo-container {
  text-align: center;
  width: 100%;
  background-color: inherit; /* or whatever background color your AppBar has */
}
.separator {
  border: 0;
  height: 1px;
  background: #FFFFFF;
  margin: 10px 2%;
}

.StyledToolbar {
  flex-direction: row; /* Ensure menu items are in a row layout */
  justify-content: center; /* Center menu items horizontally */
  width: 100%;
}

.settings-row {
  display: flex;
  align-items: center;
  color: #FFFFFF;
  padding-left: 150px;
  height: 30px; /* or whatever height you want */
  justify-content: space-between;
}
.svg-container {
  position: relative;
  width: 200px; /* Set your desired width */
  height: 200px; /* Set your desired height */
}

.foreground-svg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%; 
  height: 100%;
}
.epic2-background {
  background: url('../src/assets/svg/epic2.svg') no-repeat center center;
  background-size: cover;
  width: 100%; 
  height: 100vh; /* Adjust the height as needed */
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh; /* Ensure full viewport height */
}

.container > svg {
  max-width: 100%; 
  margin-bottom: 20px;
}
.container2 {
  display: flex;
  justify-content: space-between; /* This ensures that there's maximum space between the two child divs. */
}

.container > :last-child {
  margin-bottom: 0;
}
.logo {
  position: absolute; 
  top: 29px; 
  left: 84px; 
  width: 150px; 
  height: auto; 
}
.content-wrapper {
  /* display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 243px;
  padding-right: 500px;
  padding-left: 0; */
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-70%, -50%);
}

.sentence {
  font-family: 'Glory', sans-serif;  /* Assuming 'Glory' is available in your project, with a fallback to a generic sans-serif font */
  font-size: 40px;
  font-weight: 700;
  color: white;
  line-height: 50px;
  letter-spacing: 0.01em;
  text-align: left;
  
}

.button-group {
  display: flex;
  /* justify-content: space-between; */
  width: 780px;
  height: 34px;
  margin-top: 20px;  /* Just a placeholder, adjust according to your needs */
  gap: 10px;        /* Gap between buttons */
  /* margin-right: 200px; */
}
.button-group .MuiButton-contained:hover {
  background-color: transparent; /* This ensures that there's no background color on hover */
  box-shadow: none; /* This removes the Material-UI default hover shadow */
}

/* If you want to change the text color on hover, you can do so like this */
.button-group .MuiButton-contained:not(.active):hover {
  color: [YourHoverColor];  /* Replace [YourHoverColor] with the color you want on hover for the non-active button */
}

.button-group .MuiButton-contained {
  height: 34px;
  background-color: transparent;   /* No background */
  color: #83D3CA80;                /* Default color */
  transition: color 0.3s;         /* Smooth color transition */
  border: none;                    /* No border */
  box-shadow: none;                /* Remove MUI default box-shadow */
  text-transform: none;           /* Remove MUI default uppercase transform */

  /* Your specified font styles */
  font-family: 'Glory', sans-serif; /* Ensure 'Glory' font is loaded in your project */
  font-size: 28px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.01em;
  text-align: left;
}

.button-group .MuiButton-contained.active {
  color: #83D3CA;  /* Active button color */
}
.search-bar {
  display: flex;
  align-items: center;
  width: 536px;
  height: 40px;
  margin-top: 20px;
  /* margin-right: 100px; */
  /* background-color: #415250; */
  /* padding-left: 10px; */
  /* justify-content: space-between; */
}
.tech-search-bar {
  position: absolute;
  top: 50%;
  left: 9%;
  /* margin-left: 120px; */
  display: flex;
  align-items: center;
  width: 65%;
  height: 40px;
  /* margin-top: 120px; */
  background-color: #39635C;
  padding-left: 10px;
  border-radius: 5px;
}
.tech-search-bar2 {
  position: absolute;
  top: 50%;
  left: 9%;
  /* margin-left: 120px; */
  display: flex;
  align-items: center;
  width: 80%;
  height: 40px;
  /* margin-top: 120px; */
  background-color: #39635C;
  padding-left: 10px;
  border-radius: 5px;
}
.tech-title {
  position: absolute;
  top: 29%;
  left: 9%;
  color: white;
  font-family: 'Glory', sans-serif;
  font-size: 28px;
  /* margin-left: 120px; */
  /* margin-top: 70px; */
  font-weight: bold;
}
.tech-title-2 {
  position: absolute;
  top: 18%;
  left: 9%;
  color: white;
  font-family: 'Glory', sans-serif;
  font-size: 28px;
  /* margin-left: 120px; */
  /* margin-top: 70px; */
  font-weight: 500;
}
.period-button{
  /* position: absolute; */
  /* top: 51%; */
  left: 72%;
  font-family: 'Glory', sans-serif;
  font-size: 28px;
  /* margin-left: 1080px; */
  /* margin-top: 120px; */
  font-weight: bold;
}
.tech-group-1{
  font-weight: 600;
  font-family: 'Glory', sans-serif;
  color: black;
  font-size: 28px;
  margin-left: 108px;
  margin-top: 50px;
  display: flex;
  align-items: center;  /* to vertically center the items in the container */
  white-space: nowrap;  /* to prevent wrapping */
}
.tech-group-1-2{
  font-weight: 600;
  font-family: 'Glory', sans-serif;
  color: #00000066;
  font-size: 16px;
  margin-left: 108px;
  margin-top: 30px;
  display: flex;
  align-items: center;  /* to vertically center the items in the container */
  white-space: nowrap;  /* to prevent wrapping */
}
.tech-group-4{
  font-weight: 600;
  font-family: 'Glory', sans-serif;
  color: black;
  font-size: 28px;
  margin-left: 108px;
  margin-top: 20px;
  display: flex;
  align-items: center;  /* to vertically center the items in the container */
  white-space: nowrap;  /* to prevent wrapping */
}
.tech-group-3{
  font-weight: 600;
  font-family: 'Glory', sans-serif;
  color: black;
  font-size: 28px;
  margin-left: 108px;
  margin-top: 50px;
  display: flex;
  align-items: center;  /* to vertically center the items in the container */
  white-space: nowrap;  /* to prevent wrapping */
}
.tech-group-2{
  font-weight: 600;
  font-family: 'Glory', sans-serif;
  color: black;
  font-size: 28px;
  margin-left: 108px;
  margin-top: 400px;
  display: flex;
  align-items: center;  /* to vertically center the items in the container */
  white-space: nowrap;  /* to prevent wrapping */
}
.tableContainer {
  padding: 40px;
  padding-left: 100px;
  padding-right: 100px;
}
.search-icon {
  color: #fff;
  /* margin-right: 10px; */
}
.tech-search-icon {
  color: #fff;
}
.search-bar .MuiInputBase-root {
  color: #fff;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2C5750;
  height: 60px;  /* Adjust as needed */
  padding: 0 20px;  /* 20px padding on left and right */
  position: sticky;
}
.navbar2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #FFFFFF;
  height: 60px;  /* Adjust as needed */
  padding: 0 20px;  /* 20px padding on left and right */
  position: sticky;
}
.tech-group-small {
  font-family: Glory;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.01em;
  margin-left: 108px;
  margin-top: 22px;
}
.tech-group-small:hover {
  font-weight: 500;
}


.search-icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3D766F;
  width: 40px;  /* Adjust as needed */
  height: 40px;  /* Adjust as needed */
  border-radius: 50%;  /* Makes it a circle */
  cursor: pointer; /* Indicates clickable */
}

.search-icon {
  color: white;
  font-size: 24px;  /* Adjust as needed */
}
.background-svg {
  display: block;
  width: 100%;  /* Adjust width according to your design needs */
  height: auto; /* Adjust height if necessary */
}
.svg-with-search {
  position: relative; /* Set positioning context for child elements */
  width: 100%; /* Set to the width of your SVG or desired width */
}

.background-svg {
  display: block; /* Ensures it takes full width of the container */
  width: 100%;
  height: auto; /* Adjust if you need a specific height */
}
.period-text {
  font-family: 'Glory', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: 0.01em;
  text-align: left;
  color: #435452;
}

.ago-text {
  font-family: 'Glory', sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.01em;
  text-align: left;
  color: rgba(0, 0, 0, 0.4); /* #00000066 translated to rgba */
}
.parent-container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 100%;
}
.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent backdrop */
  z-index: 999; /* Ensure it's above all content */
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
}
.flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
/* When viewport width is 768px or less (typically tablet and below) */
@media (max-width: 768px) {
  .logo {
    width: 60px; 
    top: 8px;
    left: 8px;
  }
}

/* When viewport width is 480px or less (typically mobile phones) */
@media (max-width: 480px) {
  .logo {
    width: 40px;
    top: 6px;
    left: 6px;
  }
}
/* Your other styles ... */



canvas {
  display: block;
  padding-top: 100px;
  margin: 20px auto; /* adjust the 20px to whatever margin you want */
}
/* For Chrome, Safari */
.custom-scrollbar::-webkit-scrollbar {
  width: 8px; /* Adjust scrollbar width */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #83D3CA80; /* Color of the scroll thumb */
  border-radius: 4px; /* Optional: round scrollbar thumb */
}

/* For Firefox */
.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #83D3CA80 transparent; /* thumb and track */
}

.skeleton-cell {
  height: 50%;
  /* margin: 5px 0; */
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 100%);
  background-position: center;  /* Center the background image */
  animation: shine-lines 2s infinite linear;
}

.small-skeleton {
  width: 100%;
  height: 50%;
  animation: shine-small 2s infinite linear;
}

.keyword-skeleton {
  flex-grow: 1;
}

.trend-skeleton {
  width: 100px;
}

@keyframes shine-lines {
  0% {
    background-position: -100px center; 
  }
  40%, 100% {
    background-position: 250px center;
  }
}

@keyframes shine-small {
  0% {
    background-position: -40px;
  }
  40%, 100% {
    background-position: 80px;
  }
}
@keyframes wave {
  50%,
  75% {
    transform: scale(2.5);
  }

  80%,
  100% {
    opacity: 0;
  }
}

